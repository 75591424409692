"use client";

import { createGlobalStyle } from "styled-components";
import { MEDIA_SCREENS, THEME_COLORS, CSS_HELPERS } from "./constants";
const mobile_font_titles = 0.9;
const mobile_font_p = 0.8;
const regular_font = 1;

// export function getBrowserName() {
//     var name = "Unknown";
//     if(navigator.userAgent.indexOf("MSIE")!=-1){
//         name = "MSIE";
//     }
//     else if(navigator.userAgent.indexOf("Firefox")!=-1){
//         name = "Firefox";
//     }
//     else if(navigator.userAgent.indexOf("Opera")!=-1){
//         name = "Opera";
//     }
//     else if(navigator.userAgent.indexOf("Chrome") != -1){
//         name = "Chrome";
//     }
//     else if(navigator.userAgent.indexOf("Safari")!=-1){
//         name = "Safari";
//     }
//     return name;   
// }

const GlobalStyle = createGlobalStyle`
    // capitaliza los meses
    .ant-picker-cell-inner{
        text-transform: Capitalize;
    }


    @page { size: auto;  margin: 0mm; }

    @media print {
        .transac-layout{
            height: auto !important;
        }
        .footer {
            display: none !important;
        }
        .box_ui {
            display: none !important;
        }
        .logo-genesis{
            position: fixed !important;
            top: 20px !important;
            left: 20px !important;
        }
        .userOptions { 
            display: none;
        }
    }

    // DROP DOWN MENU DON'T TOUCH THIS PLS
    .ant-dropdown-menu-item, 
    .ant-dropdown-menu-submenu-title,
    li.ant-dropdown-menu-item-only-child{
        padding: 0;
    }

    .ant-dropdown-menu-item:hover{
        background-color: #fff !important;
    }

    
    .retiros-alert .ant-modal-content{
        border-radius: 15px;
        font-size: 12px;
    }

    .retiros-alert .ant-modal-content p{
        font-size: 12px;
    }

    .h-70 {
        height: 70vh;
    }

    // .ant-carousel .slick-prev,
    // .ant-carousel .slick-prev:hover,
    // .ant-carousel .slick-prev:focus {
    //     font-size: 30px;
    //     left: 25px;
    //     z-index: 2;
    //     color: gray;
    // }

    // .ant-carousel .slick-next,
    // .ant-carousel .slick-next:hover,
    // .ant-carousel .slick-next:focus {
    //     font-size: 30px;
    //     right: 25px;
    //     z-index: 2;
    //     color: gray;
    // }

    
        user-select: none;
      
        /* @font-face {
            font-family: "NotoSans";
            font-weight:bold;
            src: url("/fonts/noto/NotoSans-Bold.ttf") format('woff');
        }
        @font-face {
            font-family: "NotoSans";
            src: url("/fonts/noto/NotoSans-BoldItalic.ttf") format('woff');
        }
        @font-face {
            font-family: "NotoSans";
            src: url("/fonts/noto/NotoSans-Italic.ttf") format('woff');
        }
        @font-face {
            font-family: "NotoSans";
            src: url("/fonts/noto/NotoSans-Regular.ttf") format('woff');
        }
         */

        .strong200{
            font-weight: 200;
        }
        .strong300{
            font-weight: 300;
        }
        .strong400{
            font-weight: 400;
        }
        .strong500{
            font-weight: 500;
        }
        .strong600{
            font-weight: 600;
        }
        .strong900{
            font-weight: 900;
        }
        .uppercase{
            text-transform:uppercase ;
        }
        .uppa{
            text-transform:uppercase ;
        }
        .cappi{
            text-transform:capitalize ;

        }
        .light_secondary_main{
            color: ${THEME_COLORS.light.secondary.main} 
        }

        .light_primary_contrastText{
            color:  ${THEME_COLORS.light.primary.contrastText} 
        }

        .light_primary_main{
            color: ${THEME_COLORS.light.primary.main} 
        }

        .light_secondary_contrastText{
            color:  ${THEME_COLORS.light.primary.contrastText} 
        }

        h1.ant-typography, 
        h2.ant-typography, 
        h3.ant-typography, 
        h4.ant-typography, 
        h5.ant-typography  {
            overflow-wrap: break-word;
            font-weight: 600;
            color: ${THEME_COLORS.light.text.titles};
            font-family: "Noto Sans", sans-serif;
        }

        p{
            margin:0 ;

        }
      
        @media screen and  (min-width: ${0}px) { 
            h1.ant-typography { font-size: ${1.8 * mobile_font_titles}rem }
            h2.ant-typography { font-size: ${1.6 * mobile_font_titles}rem }
            h3.ant-typography { font-size: ${1.5 * mobile_font_titles}rem }
            h4.ant-typography { font-size: ${1.2 * mobile_font_titles}rem }
            h5.ant-typography { font-size: ${.9 * mobile_font_titles}rem }
            p.xxs{font-size: ${.6 * mobile_font_p}rem }
            p.xs {font-size:  ${.8 * mobile_font_p}rem }
            p.sm{font-size:  ${.9 * mobile_font_p}rem }
            p {font-size: ${1.2 * mobile_font_p}rem }
            p.lg {font-size: 1.5rem }
            a.xxs{font-size: ${.6 * mobile_font_p}rem }
            a.xs {font-size:  ${.8 * mobile_font_p}rem }
            a.sm{font-size:  ${.9 * mobile_font_p}rem }
            a {font-size: ${1.2 * mobile_font_p}rem }
            a.lg {font-size: 1.5rem }
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.LG}px) { 
            h1.ant-typography { font-size: ${1.8 * regular_font}rem }
            h2.ant-typography { font-size: ${1.6 * regular_font}rem }
            h3.ant-typography { font-size: ${1.5 * regular_font}rem }
            h4.ant-typography { font-size: ${1.2 * regular_font}rem }
            h5.ant-typography { font-size: ${.9 * regular_font}rem }
            p.xxs{font-size: ${.6 * regular_font}rem }
            p.xs {font-size:  ${.8 * regular_font}rem }
            p.sm{font-size:  ${.8 * regular_font}rem }
            p {font-size: ${1.2 * regular_font}rem }
            p.lg {font-size: 1.5rem }
            a.xxs{font-size: ${.6 * regular_font}rem }
            a.xs {font-size:  ${.8 * regular_font}rem }
            a.sm{font-size:  ${.9 * regular_font}rem }
            a {font-size: ${1.2 * regular_font}rem }
            a.lg {font-size: 1.5rem }
        }
        .strong{ 
            font-weight:600 
        };
        .subtitle { 
            color:${THEME_COLORS.light.primary.alt} 
        }
   

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.txt-center {
    text-align: center
}

.logo-genesis {
    cursor: pointer ;
}

.text-white {
    color: white;
}

.txt-white {
    color: white;
}

.txt-right {
    text-align: right;
}

.m-auto {
    margin: auto;
}

.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.flex {
    display: flex;
}

.h-inherit {
    height: inherit;
}

.ant-drawer-body {
    /* padding: 0; */
}

.p-5 {
    padding: 5px;
}

.mt-30 {
    margin-top: 30px;
}

.w-100 {
    width: 100%;
}

.hidden {
    display: none;
}

.BaseDrawerMenu .ant-drawer-content-wrapper {
    height: 220px !important;
}

.ant-drawer,
.ant-drawer-mask,
.ant-drawer-content-wrapper {
    border: 0 !important;
}

.verticalDrawerMenu .ant-drawer-content-wrapper {
    width: 250px;
}

.BaseDrawerMenu .ant-drawer-body {
    padding: 0px;
  }

.BaseDrawerMenu .ant-drawer-content {
    border-radius: 20px 20px 0 0;
}

.ant-drawer-title {
    /* font-size: 14px */
}


.drawerConfirmarRetiroEstrategico .ant-drawer-body,
.drawerRetiro1 .ant-drawer-body {
    padding: 0;
}

/* .drawerConfirmarRetiroEstrategico .ant-drawer-body, */
.drawerRetiro1 .ant-page-header {
    padding: 16px 24px;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${THEME_COLORS.light.primary.main}
}
.primary {
    background-color: #455a7e;
    border-color: #455a7e;
    color: white;
}
.mb-25 {
    margin-bottom: 25px;
}


.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb3 {
    margin-bottom: 3% ;
}

.m-0 {
    margin: 0 !important;
}

.mt10 {
    margin-top: 10%;
}


.mt3 {
    margin-top: 3%;
}


.mt5 {
    margin-top: 5%;
}

.mt6 {
    margin-top: 6%;
}

.mt-6 {
    margin-top: 6px;
}

.pt5 {
    padding-top: 5%
}


.mb5 {
    margin-bottom: 5%;
}

.mb0 {
    margin-bottom: 0;
}

.fz-20 {
    font-size: 20px;
}

.fz-10 {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mb2 {
    margin-bottom: 2%;
}

.hoverable:hover {
    background: #fbfbfb;
}

.mb-10 {
 margin-bottom: 10px !important;
}


.mt-20 {
 margin-top: 20px;
}

.mb-20 {
 margin-bottom: 20px;
}

.flex-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.text-center {
    text-align: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-r-10 {
  padding-right: 10px;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: "Noto Sans", sans-serif !important;
  font-weight:400 ;
  /* color:${THEME_COLORS.light.text.paragraph}; */

}

a {
  color: inherit;
  text-decoration: none;
}

p {
    margin: 0;

}

* {
  box-sizing: border-box;
}

body div#__next {
  height: 100%;
  width: 100%;
}





.balance__tag {
    font-size: 13px;
    font-weight: bold;
}

.ant-table-cell {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.f-normal {
    font-weight: normal !important;
}


.info__person {
    font-weight: bold;
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
    margin: 10px !important;
}

.width-100 {
    width: 100% !important;
}

.text-right {
    text-align: right;
}


.m-auto {
    margin: auto !important;
}

.ftz-13 {
    font-size: 13px;
}

.ftz-14 {
    font-size: 14px;
}

table thead tr th {
    text-align: center;
}

.flex {
    display: flex;
    align-items: center;
}


.ant-list-split .ant-list-item {
    border: none;
}


li.ant-list-item.casa:hover{
    background: #f9f9f9;
}



.ant-menu-overflow-item.ant-menu-item {
    display: flex;
    justify-content:center;
    align-items:center;
}


.border-rounded {
    border-radius: 0.6em
}

.site-page-header .ant-drawer-close {
    display: none;
}


/*******************/


.ant-picker-panels {
        width: 100%;
      
      @media (max-width: ${MEDIA_SCREENS.XS}px) { 
        display: flex;
        flex-direction: column;
      }
     
};



.ant-card{
    ${CSS_HELPERS.ROUNDED};
};
.ant-modal-content{
    ${CSS_HELPERS.ROUNDED};
};



.ant-btn {
    /* border-radius: .9rem; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:.5em;
    span{
        font-size:1em;
        /* font-weight:600; */
    }
    &.ant-btn-circle {
        border-radius:50%;
    }
}

.btn_custom_secondary {
    border-color: ${THEME_COLORS.light.secondary.main};
    background-color: ${THEME_COLORS.light.secondary.main};
    color: ${THEME_COLORS.light.secondary.contrastText};

    :hover, :focus {
        border-color: ${THEME_COLORS.light.secondary.main};
        background-color: ${THEME_COLORS.light.secondary.contrastText};
        color: ${THEME_COLORS.light.secondary.main};
    }
    :active{
        border-color: ${THEME_COLORS.light.secondary.alt};
        background-color: ${THEME_COLORS.light.secondary.contrastText};
        color: ${THEME_COLORS.light.secondary.main};
    }
 
}


//:: GLOBAL UI
img {
    /* background-image: url('${process.env.IMAGE_LOADER}') */
}


//:: AND CUSTOMIZE


// MAIN ELEMENTS SHAPES UI


// INPUT NUMBER
.ant-input-number-input-wrap {
    height: 100%;
    .ant-input-number-input{
        height: 100%;
    }
}


//TEXT FIELD
.ant-input {
    height: 2.5em;
    border-radius:${CSS_HELPERS.RADIUS};
    background-color:${THEME_COLORS.light.bg.main};
}
.ant-input-affix-wrapper{
    border-radius:${CSS_HELPERS.RADIUS};
    padding: 0 11px;
    background-color:${THEME_COLORS.light.bg.main} ;
    border: 1px solid ${THEME_COLORS.light.primary.alt};
    .ant-input{
        /* height:3.2em; */
    }
}


///TEXT FIELD NUMBER 
.ant-input-number{
    height: 3.2em;
    border-radius:${CSS_HELPERS.RADIUS};
    background-color:${THEME_COLORS.light.bg.main}; 
    width:100%;
}


// SELECT
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    align-items: center;
    border-radius:10px;
}

.ant-select-arrow {
    margin-top: -8px;
}

.ant-select {
    height: 3.2em;
    border-radius:${CSS_HELPERS.RADIUS} ;
    /* background-color:${THEME_COLORS.light.bg.alt} ; */
}

// DATE PICKER
.ant-picker{
    border-radius:${CSS_HELPERS.RADIUS} ;
    width: 100%;
    /* height: 3.2em; */
}

.ant-drawer-header {
    padding: 10px;
}

.ant-input-group{
    .ant-input{
        border-radius: 9pt 0 0 9pt;
        height: 2.5em;  
    }
    .ant-input-group-addon{
        height: 2.5em;
        width: 3.2em;
        border-radius: 0 9pt 9pt 0;
       overflow: hidden;
    }
    .ant-input-search-button {
       overflow: hidden;
       width: 100%;
       height: 100%;
       border-radius: 0 9pt 9pt 0 !important;
    }
}
.ant-image-mask{
    border-radius: ${CSS_HELPERS.RADIUS};
}


//DRAWER BOTTOM
.ant-drawer-bottom {
    @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) { 
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
.ant-drawer-bottom .ant-drawer-content-wrapper{

    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
        width: 100vw;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.SM}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
        width: 100vw;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) { 
        width: 90vw;
    }
}   
//END DRAWER BOTTOM
.custom_drawer{
    .ant-drawer-content{
        background:${THEME_COLORS.light.primary.alt + '00'};
    }
}

       
// END ANT DRAWER   


/* custom scrollbar */
/* ::-webkit-scrollbar {
  width: 16px;
} */

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee196;
  border-radius: 20px;
  border: 6px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.bx-siniestro-confirm::-webkit-scrollbar {
    width: 0px !important;
}

.hideXs{
    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
            display:none;
    }
}

.noti{
    background-color:${THEME_COLORS.dark.bg.main};
   
    border-radius:${CSS_HELPERS.RADIUS};

    .ant-notification-notice-message, span{
        color:${THEME_COLORS.light.secondary.main};

    }
}


.ant-notification-notice-message {
    font-size: 15px !important;
    line-height: 1.4 !important;
}


.ant-list-item-meta {
    align-items: center;
}



// MODAL DESIGN
.ant-modal-confirm-btns{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.drawer-siniestros .ant-drawer-body,
.drawer-siniestros-confirm .ant-drawer-body{
    padding: 0;
}

.drawer-siniestros .ant-drawer-content-wrapper {
    width:100%;
}

.drawer-siniestros .site-page-header,
.drawer-siniestros-confirm .site-page-header {
    background-color: #f0f2f5;
}

.drawer-ip-update,
.drawer-login-password,
.drawer-login-password .ant-drawer-content {
    border-radius: 10px;
    height: inherit !important;
}


.drawer-login-new-form .ant-drawer-title {
    font-size: 13px;
    font-weight: bold;
}

.drawer-siniestros-confirm .ant-drawer-body {
    overflow: hidden;
}


.drawer-siniestros-confirm .bx-siniestro-confirm {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 72px;
    background-color: #fbfbfb;
}
.drawer-siniestros-confirm .bx-siniestro-confirm .ant-card:nth-child(1) {
    width: 60%;
    margin: auto;
    margin-top: 10px;
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
     width: auto;
     margin:5px;
    }
    .ant-modal-content {
        border-radius: ${CSS_HELPERS.RADIUS};
    }

    .ant-notification, .ant-notification-notice{
        border-radius:${CSS_HELPERS.RADIUS};
        overflow:hidden;
        padding:3em;
    }
}


.modal_session .ant-modal-footer {
		display: none;
}

.modal_session .ant-result-extra {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_residencia .ant-form-item-control-input-content {
		display: flex;
		justify-content: center;
		align-items: center;
}

.modal_residencia .ant-modal-content .ant-modal-close {
		display: none;
}

.modal_residencia .ant-modal-content .ant-modal-close {
    display: block;
}

.modal_residencia .ant-modal-content .ant-modal-body {
    //background-color: #fafafa;
}

.modal_residencia .ant-modal-content {
    border-radius: 10px;
}

.modal_residencia #form_residencia_fiscal .space-residencia-fiscal {
		gap: 8px;
    	background: white;
    	padding: 15px;
    	margin: 5px 0;
}

.ant-modal.modal-siniestros {
    max-width: 100% !important;
    /* @media (max-width: ${MEDIA_SCREENS.SM}px) {
     max-width: auto !important;   
    } */
}

.modal_residencia .ant-form-item {
    margin-bottom: 0;
}


.modal-siniestros .ant-modal-footer {
    display: none;
}

.modal-siniestros .ant-modal-content {
    border-radius: 10px;
}

.modal-siniestros .ant-modal-body .ant-page-header {
    padding: 0 0 16px;
}

.modal-siniestros .ant-modal-body {

    
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
        padding: 24px 15px;
    }
}


.ant-col.menu_card>div {
    width:100%;
}


.ant-steps-item-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}


.ant-collapse-item {
    //border-bottom: 0px !important;
}

.ant-pagination-item-link {
    border: 1px solid #d9d9d9 !important;
}

`;

export default GlobalStyle;
