"use client";

import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import { AntdRegistry } from '@ant-design/nextjs-registry';

const Provider = ({ children }) => {
    return (
        <ThemeProvider theme={{ mode: 'light' }}>
            <GlobalStyle />
            <AntdRegistry>
                {children}
            </AntdRegistry>
        </ThemeProvider>
    )
}

export default Provider